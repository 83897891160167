import { SAVECUESTIONARY } from '../config/app';
import cnConsole from '../utils/cnConsole';

export const saveCuestionary = async ({ q1, q2, q3, q4 }) => {
  const body = new FormData();
  body.append('q1', q1);
  body.append('q2', q2);
  body.append('q3', q3);
  body.append('q4', q4);

  const request = await fetch(SAVECUESTIONARY, {
    body,
    method: 'POST',
  });
  const json = await request.json();
  cnConsole(json);
  return json;
};
