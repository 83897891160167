import React, { useRef, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/header/logo-blanco.svg';
import logoHorizontal from '../../assets/images/header/logo-horizontal-blanco.svg';
import { HeaderContext } from '../../context/HeaderContext';
import LanguageSelector from '../language-selector/LanguageSelector';
import { useEffect } from 'react';

import telefonica from '../../assets/images/telefonica.png';
import telefonica2x from '../../assets/images/telefonica@2x.png';
import telefonica3x from '../../assets/images/telefonica@3x.png';

//TODO: necesitamos poder poner imagen de fondo y que haya una transicion entre ambas
//ademas necesitamos poder hacer que cuando haya imagen tambien poder poner una sombra en
//el logo

export default React.forwardRef(function Header(props, ref) {
  const headerContext = useContext(HeaderContext);

  const location = useLocation();
  const telefonicaRef = useRef(null);

  useEffect(() => {
    const displayTelefonica =
      location.pathname.search('/sala/') >= 0 ? 'block' : 'none';
    telefonicaRef.current.style.display = displayTelefonica;
  }, [location.pathname]);

  const logoRef = useRef(null);
  const logoImagen = headerContext.isHorizontalLogo ? logoHorizontal : logo;
  return (
    <header className='header' ref={ref}>
      {headerContext.isLanguageShown && (
        <LanguageSelector className='language-selector--header' />
      )}
      {headerContext.backgroundImage && (
        <div className='header__bgimage'>
          <img src={headerContext.backgroundImage} alt='' />
        </div>
      )}
      <div className='header__container'>
        <div className='header__logo'>
          <Link
            className={`header__link${
              headerContext.isHeaderSmall ? ' header__link--small' : ''
            }`}
            to='/'
            ref={logoRef}
          >
            <img
              src={logoImagen}
              alt='Teatro Real - Visitas Tours'
              className={`header__image header__image--logo ${
                headerContext.isHorizontalLogo
                  ? 'header__image--horizontal'
                  : ''
              } ${headerContext.hasShadow ? 'header__image--shadow' : ''}`}
            />
          </Link>
        </div>
        <div ref={telefonicaRef} className='telefonica__logo--header'>
          <img
            src={telefonica}
            srcSet={`${telefonica2x} 2x,
             ${telefonica3x} 3x`}
            className='telefonica__image'
            alt='telefónica'
          ></img>
        </div>
      </div>
    </header>
  );
});
