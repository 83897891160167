import React, { useContext, useRef } from 'react';

import telefonica from '../../assets/images/telefonica.png';
import telefonica2x from '../../assets/images/telefonica@2x.png';
import telefonica3x from '../../assets/images/telefonica@3x.png';

import { HeaderContext } from '../../context/HeaderContext';
import { useLocation } from 'react-router-dom';
// eslint-disable-next-line
import cnConsole from '../../utils/cnConsole';
import { useEffect } from 'react';

export const Telefonica = () => {
  const headerContext = useContext(HeaderContext);

  const location = useLocation();
  const telefonicaRef = useRef(null);

  useEffect(() => {
    const display = location.pathname.search('/sala/') >= 0 ? 'none' : 'flex';
    telefonicaRef.current.style.display = display;
  }, [location.pathname]);

  return (
    <div
      ref={telefonicaRef}
      className={`telefonica ${
        headerContext.backgroundImage ? 'telefonica--sala' : ''
      }`}
    >
      <img
        src={telefonica}
        srcSet={`${telefonica2x} 2x,
             ${telefonica3x} 3x`}
        className='telefonica__image'
        alt='telefónica'
      ></img>
    </div>
  );
};
