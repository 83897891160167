import React, { useRef, useEffect, useContext } from 'react';
import { Transition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import { HeaderContext } from '../../context/HeaderContext';
import { GlobalContext } from '../../context/GlobalContext';
// eslint-disable-next-line
import cnConsole from '../../utils/cnConsole';

const startState = { autoAlpha: 0, y: 50 };


const SeleccionSala = ({ show }) => {
  //refs
  const nodeRef = useRef(null);

  // context & state
  const globalContext = useContext(GlobalContext);
  const headerContext = useContext(HeaderContext);

  console.log('appdata',globalContext.appData);
  

  // Setup header
  useEffect(() => {
    if (show) {
      headerContext.setHeaderSmall(true);
      headerContext.setLaguageShown(true);
      headerContext.setHorizontalLogo(false);
      headerContext.setHasShadow(false);
      headerContext.setBackgroundImage(null);
      globalContext.setAppScroll('');
    }
    // Ponemos el logo de telofonica en modo sticky
    const className = 'app--header-small';
    const app = document.querySelector('.app');
    app.classList.add(className);
    return () => {
      app.classList.remove(className);
    };
    // eslint-disable-next-line
  }, [show]);

  return (
    <Transition
      unmountOnExit
      in={show}
      timeout={1000}
      appear
      nodeRef={nodeRef}
      onEnter={() => {
        gsap.set(nodeRef.current, startState);
      }}
      addEndListener={(done) => {
        gsap.to(nodeRef.current, 0.5, {
          autoAlpha: show ? 1 : 0,
          y: show ? 0 : -50,
          onComplete: done,
        });
      }}
    >
      <section ref={nodeRef} className='page page--seleccion-sala'>
        <div className='subtitle__full-center'>
          <h2 className='subtitle'>
            {globalContext.getStaticTextByKey('choose_title')}
          </h2>
        </div>
        <ul className='seleccion-sala__options'>
          {globalContext.appData?.rooms.edges.map(({ node }, index) => {
            const texts =
            globalContext.language === 'es' ? node : (globalContext.language === 'en' ? node.translations[0] : node.translations[1])

            return (
              <li key={node.id} className='seleccion-sala__item'>
                {cnConsole()}
                {node.room_data.activa && (
                  <Link
                    to={`/sala/${node.slug}`}
                    title={texts.title}
                    className='seleccion-sala__button button-round'
                  >
                    {index + 1}
                  </Link>
                )}
                {!node.room_data.activa && (
                  <button
                    title={texts.title}
                    disabled
                    className='seleccion-sala__button button-round seleccion-sala__button--disabled'
                  >
                    {index + 1}
                  </button>
                )}
              </li>
            );
          })}
        </ul>
      </section>
    </Transition>
  );
};

export default SeleccionSala;
