import React, { useRef, useEffect, useContext } from "react";
import { Transition } from "react-transition-group";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { HeaderContext } from "../../context/HeaderContext";
import { GlobalContext } from "../../context/GlobalContext";
// eslint-disable-next-line
import cnConsole from "../../utils/cnConsole";

const startState = { autoAlpha: 0, y: 50 };

const SeleccionVersion = ({ show }) => {
  //refs
  const nodeRef = useRef(null);

  // context & state
  const globalContext = useContext(GlobalContext);
  const headerContext = useContext(HeaderContext);

  // Setup header
  useEffect(() => {
    if (show) {
      headerContext.setHeaderSmall(true);
      headerContext.setLaguageShown(true);
      headerContext.setHorizontalLogo(false);
      headerContext.setHasShadow(false);
      headerContext.setBackgroundImage(null);
      globalContext.setAppScroll("");
    }
    // Ponemos el logo de telofonica en modo sticky
    const className = "app--header-small";
    const app = document.querySelector(".app");
    app.classList.add(className);
    return () => {
      app.classList.remove(className);
    };
    // eslint-disable-next-line
  }, [show]);

  return (
    <Transition
      unmountOnExit
      in={show}
      timeout={1000}
      appear
      nodeRef={nodeRef}
      onEnter={() => {
        gsap.set(nodeRef.current, startState);
      }}
      addEndListener={(done) => {
        gsap.to(nodeRef.current, 0.5, {
          autoAlpha: show ? 1 : 0,
          y: show ? 0 : -50,
          onComplete: done,
        });
      }}
    >
      <section ref={nodeRef} className="page page--seleccion-sala">
        <div className="subtitle__full-center">
          <h2 className="subtitle">
            {globalContext.getStaticTextByKey("choose_version")}
          </h2>
        </div>
        <ul className="seleccion-sala__options">
          <li className="seleccion-sala__item">
            <Link to={`/idioma/`} className="seleccion-version__button">
            {globalContext.getStaticTextByKey("itinerario")} 1
            </Link>
          </li>

          <li className="seleccion-sala__item">
            <Link
              to={`/idioma/?version=v2`}
              className="seleccion-version__button"
            >
              {globalContext.getStaticTextByKey("itinerario")} 2
            </Link>
          </li>
        </ul>
      </section>
    </Transition>
  );
};

export default SeleccionVersion;
