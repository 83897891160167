import gql from 'graphql-tag';




export const MAIN_QUERY_V2 = gql`
  query MainQuery {
    instruccions: pages(
      where: {
        orderby: { field: DATE, order: DESC }
        categoryName: "instrucciones"
      }
    ) {
      edges {
        node {
          title
          content
          translation(language: EN) {
            id
            title
            content
          }
          translations {
            id
            title
            content
          }
        }
      }
    }
    cuestionario: pages(
      where: {
        orderby: { field: DATE, order: DESC }
        categoryName: "Cuestionario"
      }
    ) {
      edges {
        node {
          title
          preguntas {
            preguntas {
              pregunta
              valor
              valorPorDefecto
            }
          }
          translation(language: EN) {
            id
            title
            preguntas {
              preguntas {
                pregunta
                valor
                valorPorDefecto
              }
            }
          }
          translations {
            id
            title
            preguntas {
              preguntas {
                pregunta
                valor
                valorPorDefecto
              }
            }
          }
        }
      }
    }
    presentation: pages(
      where: {
        orderby: { field: DATE, order: DESC }
        categoryName: "presentacion"
      }
    ) {
      edges {
        node {
          title
          content
          translation(language: EN) {
            id
            title
            content
          }
          translations {
            id
            title
            content
          }
        }
      }
    }
    notification: pages(
      where: {
        orderby: { field: DATE, order: DESC }
        categoryName: "notificacion"
      }
    ) {
      edges {
        node {
          title
          content
          translation(language: EN) {
            id
            title
            content
          }
          translations {
            id
            title
            content
          }
        }
      }
    }
    spotify: pages(
      where: { orderby: { field: DATE, order: DESC }, categoryName: "Spotify" }
    ) {
      edges {
        node {
          title
          content
          spotify {
            socialShareUrl
            socialShareTitle
            playlistUrl
            playlistTitle
            creditos
            mensajeShare
            playlistCover {
              sourceUrl
            }
          }
          translation(language: EN) {
            title
            content
            spotify {
              creditos
              mensajeShare
              socialShareUrl
              socialShareTitle
              playlistUrl
              playlistTitle
              playlistCover {
                sourceUrl
              }
            }
          }
          translations {
            title
            content
            spotify {
              creditos
              mensajeShare
              socialShareUrl
              socialShareTitle
              playlistUrl
              playlistTitle
              playlistCover {
                sourceUrl
              }
            }
          }
        }
      }
    }
    rooms: pages(
      first: 20
      where: { orderby: { field: DATE, order: ASC }, categoryName: "sala-v2" }
    ) {
      edges {
        node {
          id
          title
          content
          slug
          featuredImage {
            node {
              sourceUrl
            }
          }
          room_data {
            activa
            roomAudio {
              mediaItemUrl
            }
            roomGalery {
              id
              sourceUrl
            }
          }
          translation(language: EN) {
            id
            title
            content
            room_data {
              roomGalery {
                sourceUrl
              }
              roomAudio {
                mediaItemUrl
              }
            }
          }
          translations {
            id
            title
            content
            room_data {
              roomGalery {
                sourceUrl
              }
              roomAudio {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  }
`;
