import isProduction from '../utils/isProduction';
// https://www.estamostrabajandoenello.es/teatroreal/audioguia/api/graphql
// http://0.0.0.0:8080/api/graphql
export const ANALITYCSID = isProduction() ? 'UA-143164571-2' : 'UA-143164571-2';
export const API = isProduction()
  ? 'https://www.audiotourteatroreal.es/api/graphql'
  : 'https://www.audiotourteatroreal.es/api/graphql';
export const SAVECUESTIONARY = isProduction()
  ? 'https://www.audiotourteatroreal.es/api/insertCuestionario.php'
  : 'https://www.audiotourteatroreal.es/api/insertCuestionario.php';
