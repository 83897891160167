import React from "react";
import { ReactComponent as LoadingAnimation } from "../../assets/images/loading-animation.svg";
import { Transition } from "react-transition-group";
import { useRef } from "react";
import gsap from "gsap/gsap-core";

export const Loading = ({ show }) => {
  const loadingRef = useRef();
  return (
    <Transition
      unmountOnExit
      in={show}
      timeout={1000}
      appear
      nodeRef={loadingRef}
      onEnter={() => {
        gsap.set(loadingRef.current, { opacity: 0 });
      }}
      addEndListener={(done) => {
        gsap.to(loadingRef.current, 0.5, {
          autoAlpha: show ? 1 : 0,
          onComplete: done,
        });
      }}
    >
      <div className="loading" ref={loadingRef}>
        <div className="loaging__content">
          <figure className="loading__animation">
            <LoadingAnimation />
            <figcaption className="loading__text">
              Cargando<span className="loading__dots">...</span>
            </figcaption>
          </figure>
        </div>
      </div>
    </Transition>
  );
};
