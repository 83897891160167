import React, { useRef, useEffect, useContext, useState } from 'react';
import { HeaderContext } from '../../context/HeaderContext';
import { GlobalContext } from '../../context/GlobalContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import spotify from '../../assets/images/Spotify_logo_without_text.svg';
import { Transition } from 'react-transition-group';
import { gsap } from 'gsap';
import { sanitizeWithAllowTags } from '../../utils/clearWpParagraph';
import ScrollTop from '../common/ScrollTop';

const startState = { autoAlpha: 0, y: 50 };

const SocialShare = ({ show, data }) => {
  //refs
  const nodeRef = useRef(null);

  // context & state
  const globalContext = useContext(GlobalContext);
  const headerContext = useContext(HeaderContext);
  const [currentTexts, setCurrentTexts] = useState(data);

  // Setup header
  useEffect(() => {
    if (show) {
      headerContext.setHeaderSmall(true);
      headerContext.setLaguageShown(true);
      headerContext.setHorizontalLogo(true);
      headerContext.setHasShadow(false);
      headerContext.setBackgroundImage(null);
      globalContext.setAppScroll('app--scroll');
    }
    // eslint-disable-next-line
  }, [show]);

  // i10n texts
  useEffect(() => {
    if (data) {
      setCurrentTexts(
        globalContext.language === 'es' ? data : (globalContext.language === 'en' ? data.translations[0] : data.translations[1])
      );
    }
  }, [data, globalContext.language]);
  return (
    <Transition
      unmountOnExit
      in={show}
      timeout={1000}
      appear
      nodeRef={nodeRef}
      onEnter={() => {
        gsap.set(nodeRef.current, startState);
      }}
      addEndListener={(done) => {
        gsap.to(nodeRef.current, 0.5, {
          autoAlpha: show ? 1 : 0,
          y: show ? 0 : -50,
          onComplete: done,
        });
      }}
    >
      <section ref={nodeRef} className='page page--social-share'>
        <ScrollTop />
        <div className='social-share'>
          <div className='social-share__playlist'>
            <h3
              className='social-share__title'
              dangerouslySetInnerHTML={{
                __html: sanitizeWithAllowTags(
                  currentTexts.spotify.playlistTitle,
                  ['a']
                ),
              }}
            ></h3>
            <div className='social-share__image'>
              <figure className='social-share__cover'>
                <a
                  href={currentTexts.spotify.playlistUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {' '}
                  <LazyLoadImage
                    src={currentTexts.spotify.playlistCover.sourceUrl}
                    alt='playlist cover'
                  />
                  <div className='social-share__spotify'>
                    <img src={spotify} alt='' />
                  </div>
                </a>
              </figure>
            </div>
          </div>
          <div className='social-share__icons'>
            <h3 className='social-share__title'>
              {currentTexts.spotify.socialShareTitle}
            </h3>
            <ul className='social-share__items'>
              <li className='social-share__button'>
                <FacebookShareButton url={currentTexts.spotify.socialShareUrl}>
                  <FacebookIcon size={53} round={true} />
                </FacebookShareButton>
              </li>
              <li className='social-share__button'>
                <TwitterShareButton
                  url={`${currentTexts.spotify.mensajeShare} ${currentTexts.spotify.socialShareUrl}`}
                >
                  <TwitterIcon size={53} round={true} />
                </TwitterShareButton>
              </li>
              <li className='social-share__button'>
                <WhatsappShareButton
                  url={`${currentTexts.spotify.mensajeShare} ${currentTexts.spotify.socialShareUrl}`}
                >
                  <WhatsappIcon size={53} round={true} />
                </WhatsappShareButton>
              </li>
            </ul>
          </div>
          <div
            className='social-share__goodbye'
            dangerouslySetInnerHTML={{
              __html: sanitizeWithAllowTags(currentTexts.content, [
                'i',
                'em',
                'br',
                'strong',
              ]),
            }}
          ></div>
          <div
            className='social-share__credits'
            dangerouslySetInnerHTML={{
              __html: sanitizeWithAllowTags(currentTexts.spotify.creditos, [
                'p',
                'br',
              ]),
            }}
          ></div>
        </div>
      </section>
    </Transition>
  );
};

export default SocialShare;
