import React, { useState } from "react";

import logo from "../../assets/images/header/logo-horizontal-color.svg";
import { useEffect } from "react";
import LanguageSelector from "../language-selector/LanguageSelector";
import { useRef } from "react";
import gsap from "gsap";
import { Transition } from "react-transition-group";
import { sanitizeWithAllowTags } from "../../utils/clearWpParagraph";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
// eslint-disable-next-line
import cnConsole from "../../utils/cnConsole";
import { Link } from "react-router-dom";

const startState = { autoAlpha: 0, y: 0 };

export default function Modal({ isVisible = false, onClose, data }) {
  const modalRef = useRef(null);
  const globalContext = useContext(GlobalContext);
  const [currentTexts, setCurrentTexts] = useState(data);

  useEffect(() => {
    const body = document.querySelector("body");
    const html = document.querySelector("html");
    if (isVisible) {
      // Al abrir modal quitamos el scroll del body
      body.classList.add("remove-scroll");
      html.classList.add("remove-scroll");
      return () => {
        body.classList.remove("remove-scroll");
        html.classList.remove("remove-scroll");
      };
    } else {
      body.classList.remove("remove-scroll");
      html.classList.remove("remove-scroll");
    }
  }, [isVisible]);

  // Cuando cambie el idioma o se reciban datos tenemos que ver los textos de donde sales
  useEffect(() => {
    if (data) {
      setCurrentTexts(        
        globalContext.language === 'es' ? data : (globalContext.language === 'en' ? data.translations[0] : data.translations[1])
      );
    }
  }, [data, globalContext.language]);

  return (
    <Transition
      unmountOnExit
      in={isVisible}
      timeout={1000}
      appear
      nodeRef={modalRef}
      onEnter={() => {
        gsap.set(modalRef.current, startState);
      }}
      addEndListener={(done) => {
        gsap.to(modalRef.current, 0.5, {
          autoAlpha: isVisible ? 1 : 0,
          y: isVisible ? 0 : 50,
          onComplete: done,
        });
      }}
    >
      <div className="modal" ref={modalRef}>
        <LanguageSelector className="language-selector--modal" />
        <div className="modal__logo">
          <img
            src={logo}
            alt="Teatro Real - visitas, tours"
            className="modal__image"
          />
        </div>
        {data?.title && (
          <>
            <div className="subtitle__full-center">
              <h2 className="modal__title subtitle">{currentTexts.title}</h2>
            </div>
            <div
              className="modal__text"
              dangerouslySetInnerHTML={{
                __html: sanitizeWithAllowTags(currentTexts.content),
              }}
            ></div>
          </>
        )}
        <div className="navigation modal__navigation">
          <Link
            to="/instrucciones"
            className="navigation__button"
            onClick={() => {
              globalContext.setIsModalVisible(false);
            }}
          >
            {globalContext.getStaticTextByKey("accept")}
          </Link>
        </div>
      </div>
    </Transition>
  );
}
