import React, { useRef, useContext, useState, useEffect } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import { Transition } from 'react-transition-group';
import { gsap } from 'gsap';
import { HeaderContext } from '../../context/HeaderContext';
import { sanitizeWithAllowTags } from '../../utils/clearWpParagraph';
import { Link } from 'react-router-dom';
// Images
import es from '../../assets/images/bandera-espa-a.svg';
import en from '../../assets/images/bandera-inglesa.svg';
import fr from '../../assets/images/bandera-francesa.svg';
import teatro from '../../assets/images/teatro.png';
import teatro2x from '../../assets/images/teatro@2x.png';
import teatro3x from '../../assets/images/teatro@3x.png';

import { MAIN_QUERY } from "../../queries/MainQuery";
import { MAIN_QUERY_V2 } from "../../queries/MainQueryV2";
import { useQuery } from "@apollo/react-hooks";
import cnConsole from "../../utils/cnConsole";

const startState = { autoAlpha: 0, y: 0 };

export default function Languaje({ show, data1, onNext }) {
  //refs
  const languageRef = useRef(null);
  const nodeRef = useRef(null);

  var get_params = function(search_string) {

    var parse = function(params, pairs) {
    var pair = pairs[0];
    var parts = pair.split('=');
    var key = decodeURIComponent(parts[0]);
    var value = decodeURIComponent(parts.slice(1).join('='));

    // Handle multiple parameters of the same name
    if (typeof params[key] === "undefined") {
        params[key] = value;
    } else {
        params[key] = [].concat(params[key], value);
    }

    return pairs.length == 1 ? params : parse(params, pairs.slice(1))
    }

    // Get rid of leading ?
    return search_string.length == 0 ? {} : parse({}, search_string.substr(1).split('&'));
}

   // Cargamos los datos
   var params = get_params(window.location.search);
console.log(params["version"]);
console.log("carga MAINQUEY " + params["version"]);
var REFRESH_QUERY = '';
if(params['version'] === 'v2'){
   REFRESH_QUERY = MAIN_QUERY_V2;  
} else {
   REFRESH_QUERY = MAIN_QUERY;
}
console.log('repetir query',MAIN_QUERY_V2);
const { data } = useQuery(REFRESH_QUERY);  

  console.log('data con versión', data);
   

  // context & state
  //----> Guardar los datos en el contexto global
  const globalContext = useContext(GlobalContext);
  useEffect(() => {
    //
    if (data) {
      cnConsole("! Data completa", data);
      globalContext.setAppData(data);
    }
    // eslint-disable-next-line
  }, [data]);
 
  const headerContext = useContext(HeaderContext);
  const [currentTexts, setCurrentTexts] = useState(data);

  // Setup header
  useEffect(() => {
    if (show) {
      headerContext.setHeaderSmall(false);
      headerContext.setLaguageShown(false);
      headerContext.setHorizontalLogo(false);
      headerContext.setHasShadow(false);
      headerContext.setBackgroundImage(null);
      globalContext.setAppScroll(false);
      globalContext.setAppIsSalas(false);
    }
    // eslint-disable-next-line
  }, [show]);

  var pageData;
  if(data){
    pageData = data.presentation.edges[0].node;
  }
  

  // i10n texts
  useEffect(() => {
    if (pageData) {
      setCurrentTexts(
        globalContext.language === 'es' ? pageData : (globalContext.language === 'en' ? pageData.translations[0] : pageData.translations[1])
      );
    }
  }, [pageData, globalContext.language]);

  return (
    <Transition
      unmountOnExit
      in={show}
      timeout={1000}
      appear
      nodeRef={nodeRef}
      onEnter={() => {
        gsap.set(nodeRef.current, startState);
      }}
      addEndListener={(done) => {
        gsap.to(nodeRef.current, 0.5, {
          autoAlpha: show ? 1 : 0,
          y: show ? 0 : -50,
          onComplete: done,
        });
      }}
    >
      <section ref={nodeRef} className='page page--select-language'>
        <div ref={languageRef} className='language'>
        {currentTexts && currentTexts.content ? (
          <div className='language__text'>
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizeWithAllowTags(currentTexts.content),
              }}
            ></span>
          </div>
        ) : null}
          <ul className='language__selector'>
            <li className='language__option'>
              <button
                className={`language__link ${
                  globalContext.language === 'es'
                    ? 'language__link--selected'
                    : ''
                } `}
                onClick={(ev) => {
                  globalContext.setLanguage('es');
                }}
              >
                <img src={es} alt='' className='language__image' />
              </button>
            </li>
            <li className='language__option'>
              <button
                className={`language__link ${
                  globalContext.language === 'en'
                    ? 'language__link--selected'
                    : ''
                } `}
                onClick={(ev) => {
                  globalContext.setLanguage('en');
                }}
              >
                <img src={en} alt='' className='language__image' />
              </button>
            </li>
            <li className='language__option'>
              <button
                className={`language__link ${
                  globalContext.language === 'fr'
                    ? 'language__link--selected'
                    : ''
                } `}
                onClick={(ev) => {
                  globalContext.setLanguage('fr');
                }}
              >
                <img src={fr} alt='' className='language__image' />
              </button>
            </li>
          </ul>
          <div className='language__navigation navigation'>
          <Link to="/instrucciones">
            <button
              className='navigation__button navigation__button--next'
              
            >
              {globalContext.getStaticTextByKey('next')}
            </button>
            </Link>
          </div>
        </div>
        <footer className='footer'>
          <img
            src={teatro}
            srcSet={`${teatro2x} 2x,
              ${teatro3x} 3x`}
            className='teatro'
            alt='imagen del museo'
          />
          <div className='footer__color-band'></div>
        </footer>
      </section>
    </Transition>
  );
}
