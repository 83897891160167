import React, { useEffect, useRef } from 'react';
import { ApolloProvider } from 'react-apollo';

import './assets/scss/main.scss';

import GlobalContextProvider from './context/GlobalContext';
import HeaderContextProvider from './context/HeaderContext';
import { client } from './apollo/client';
import MyRouter from './components/MyRouter';

function App() {
  return (
    <ApolloProvider client={client}>
      <div className='app'>
        <GlobalContextProvider>
          <HeaderContextProvider>
            <MyRouter />
          </HeaderContextProvider>
        </GlobalContextProvider>
      </div>
    </ApolloProvider>
  );
}

export default App;
